import type { GetServerSideProps, GetServerSidePropsResult } from 'next'
import { Article, Category } from '../backend/models'
import { handleAndGetTenantAsync, PageBaseProps } from '../utils/tenants'
import { shouldLanguageRedirect } from '../utils/localeHandling'
import { withRouter } from 'next/router';

type Props = {
    tenantNotFound: boolean
}

export default function Index(props: Props) {
    if (props.tenantNotFound) {
        return <div>root</div>
    }

    return null;
}

export const getServerSideProps: GetServerSideProps<Props> = async (ctx) => {
    try {
        const { tenant, auth } = await handleAndGetTenantAsync(ctx);
        const locale = (tenant.languagesHandling.defaultLanguage || 'en') as string;

        if (!auth.isFromAllowedIP) {
            return {
                notFound: true
            }
        }

        if (!auth.isAuthorized) {
            return {
                redirect: {
                    permanent: false,
                    destination: `/${locale}/auth/signin`,
                },
                props: {},
            }
        }

        const queryString = ctx?.req?.url ? new URLSearchParams(ctx.req.url?.split('?')[1])?.toString(): undefined;

        return {
            redirect: {
                permanent: false,
                destination: `/${locale}${queryString ? `?${queryString}` : ''}`
            }
        } as GetServerSidePropsResult<Props>
    } catch (e) {
        if ((e as Error).message == 'tenant not found') {
            console.log('Error: tenant not found, fallbacking to 404 (so ECS tasj can start)');

            return {
                props: {
                    tenantNotFound: true
                }
            }
        }
        throw e;
    }
}